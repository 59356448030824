import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'
import EditableEmployeeVoiceList from '../components/EditableEmployeeVoiceList.vue'
import EditableRecruitTimeLapse from '../components/EditableRecruitTimeLapse.vue'
import EditableCompanyWorkPlace from '../components/EditableCompanyWorkPlace.vue'
import CrossAssessment from '../components/CrossAssessment.vue'
import EditableCompanyNotifyAddMail from '../components/EditableCompanyNotifyAddMail.vue'
import EditableApplicantAdditionField from '../components/EditableApplicantAdditionField.vue'
import EditableApplicantDocumentList from '../components/EditableApplicantDocumentList.vue'
import RecruitCreateMessageUi from '../components/RecruitCreateMessageUi.vue'
import ApplicantEmailTemplate from '../components/ApplicantEmailTemplate/ApplicantEmailTemplate.vue'
import VueCroppie from 'vue-croppie';

Vue.use(TurbolinksAdapter)
Vue.use(VueCroppie);

// TODO refactor
document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('v-editable-employee-voices') !== null) {
    const app = new Vue({
      el: '#v-editable-employee-voices',
      components: {
        EditableEmployeeVoiceList,
      }
    })
  }
  if (document.getElementById('v-editable-recruit-time-lapse') !== null) {
    const app = new Vue({
      el: '#v-editable-recruit-time-lapse',
      components: {
        EditableRecruitTimeLapse,
      }
    })
  }
  if (document.getElementById('v-editable-company-work-place') !== null) {
    const app = new Vue({
      el: '#v-editable-company-work-place',
      components: {
        EditableCompanyWorkPlace,
      }
    })
  }
  if (document.getElementById('v-cross-assessment') !== null) {
    const app = new Vue({
      el: '#v-cross-assessment',
      components: {
        CrossAssessment,
      }
    })
  }
  if (document.getElementById('v-editable-company-notify-add-mail') !== null) {
    const app = new Vue({
      el: '#v-editable-company-notify-add-mail',
      components: {
        EditableCompanyNotifyAddMail,
      }
    })
  }
  if (document.getElementById('v-editable-applicant-addition-field') !== null) {
    const app = new Vue({
      el: '#v-editable-applicant-addition-field',
      components: {
        EditableApplicantAdditionField,
      }
    })
  }
  if (document.getElementById('v-editable-applicant-document-list') !== null) {
    const app = new Vue({
      el: '#v-editable-applicant-document-list',
      components: {
        EditableApplicantDocumentList,
      }
    })
  }
  if (document.getElementById('v-recruit-create-message-ui') !== null) {
    window.VueRecruitCreateMessageUi = new Vue({
      el: '#v-recruit-create-message-ui',
      components: {
        RecruitCreateMessageUi,
      }
    })
  }
  if (document.getElementById('v-applicant-email-template') !== null) {
    window.VueApplicantEmailTemplate = new Vue({
      el: '#v-applicant-email-template',
      components: {
        ApplicantEmailTemplate,
      }
    })
  }
})
